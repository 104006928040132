<template>
  <div class="usPic">
    <img :src="usphoto" />
    <h3>{{ us.name }}</h3>
    <h6>{{ us.mobile }}</h6>
  </div>
  <div class="usMenu">
    <ul>
      <li :class="pact === 1 ? 'act' : ''" @click="goPage(1, '/usindex')">
        个人首页
      </li>
      <li :class="pact === 2 ? 'act' : ''" @click="goPage(2, '/ustask')">
        我的任务
      </li>
      <li :class="pact === 3 ? 'act' : ''" @click="goPage(3, '/enterprise')">
        所属企业
      </li>
      <li :class="pact === 4 ? 'act' : ''" @click="goPage(4, '/evaluation')">
        任务评价
      </li>
      <li :class="pact === 5 ? 'act' : ''" @click="goPage(5, '/message')">
        消息通知
      </li>
      <li :class="pact === 6 ? 'act' : ''" @click="goPage(6, '/level')">
        经验及等级
      </li>
      <li :class="pact === 7 ? 'act' : ''" @click="goPage(7, '/certification')">
        实名认证
      </li>
      <!-- <li :class="pact === 8 ? 'act' : ''" @click="goPage(8, '/introduce')">
        个人资料
      </li> -->
      <!-- <li><el-link href="/certification">default</el-link></li> introduce-->
    </ul>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { getItem } from '@/utils/storage'
import { useRouter } from 'vue-router'
const router = useRouter()
const pact = ref(1)
const us = getItem('userinfo')
const usphoto = window.g.baseURL + us.photo

const goPage = (iv, ss) => {
  pact.value = iv
  router.push(ss)
}
</script>
