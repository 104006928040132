<template>
  <div class="headeDiv">
    <div class="heads nW">
      <div class="logo">
        <img
          class="logo curP"
          @click="goPage('/index')"
          src="@/assets/logo.png"
        />
        <img class="logoPt" style="display:;" src="@/assets/logoPt.png" />
        <div class="Ml36 Mr12">
          <el-button size="small" @click="goPage('/index')">任务大厅</el-button>
        </div>
      </div>
      <div class="headUs" v-if="utoken === ''">
        <!-- <el-button size="small" @click="goPage('/login')" type="primary">
          登录
        </el-button> -->
        <el-button size="small" @click="dialoginVisible = true" type="primary">
          登录
        </el-button>
      </div>
      <div class="headUs" v-else>
        <div class="narrowNav" @click="drawer = true">
          <svg-icon icon="nav" />
        </div>
        <div class="wideNav">
          <svg-icon icon="user" />
          <span @click="goPage('/user')">个人主页</span>
          <cite>|</cite>
          <span @click="uslogoutFun">退出</span>
        </div>
      </div>
      <div class="wapMenu">
        <el-drawer
          v-model="drawer"
          direction="ltr"
          title=""
          :with-header="false"
          size="75%"
        >
          <us-home-menu class="headNav"></us-home-menu>
          <div class="wapQuitBtn" @click="uslogoutFun">退出登录</div>
        </el-drawer>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="dialoginVisible"
    title="登录"
    width="35%"
    style="min-width:320px;"
    :before-close="handleClose"
  >
    <div class="loginDialog">
      <div class="logMx">
        <div class="fxBox inpNum">
          <el-input
            class=""
            v-model="logform.mobile"
            placeholder="请输入用户"
            :prefix-icon="Search"
            autocomplete="off"
            @blur="phoneBluer"
          >
            <!-- <template #prepend>
                <el-button :icon="Search" />
              </template> -->
          </el-input>
        </div>
        <div class="fxBox inpNum">
          <el-input
            class=""
            v-model="logform.code"
            placeholder="验证码"
            @blur="codeBluer"
            @input="codeBluerinput"
            autocomplete="off"
          />
          <el-button
            :disabled="yamDisabled"
            @click="getMobileCode"
            style="margin-left:12px;"
            type="info"
            >{{ yamTex }}</el-button
          >
        </div>
        <div>
          <el-button
            type="primary"
            @click.prevent="mobileFun"
            :disabled="entDisabled"
            size="large "
            style="width:100%"
            >登录/注册</el-button
          >
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer"> </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { sign, setItem, removeItem } from '@/utils/storage'
import { syslogin, phoneLogin, uslogout } from '@/api/sys'
import { useRouter } from 'vue-router'
import UsHomeMenu from '@/components/Public/UsHomeMenu'
import eventbus from '@/utils/eventBus'
const store = useStore()
const drawer = ref(false) // 移动端菜单
const utoken = ref(store.getters.token === '' ? '' : store.getters.token)
const dialoginVisible = ref(false)
const yamDisabled = ref(false) // 验证码按钮
const entDisabled = ref(true)

const logform = ref({
  mobile: '',
  code: ''
})
const phoneBluer = () => {
  var reg = /^1[3|4|5|7|8][0-9]{9}$/ // 验证手机号的正则表达式
  if (!reg.test(logform.value.mobile)) {
    ElMessage.warning('请输入有效手机号')
    yamDisabled.value = true
  } else {
    yamDisabled.value = false
  }
}
const codeBluer = () => {
  if (logform.value.code !== '') {
    entDisabled.value = false
  } else {
    entDisabled.value = true
  }
}

// 发验证码
const yamTex = ref('发送验证码')
const getMobileCode = async () => {
  const ifh = await syslogin(sign({ mobile: logform.value.mobile }))
  if (ifh === 'OK') {
    // console.log('验证码---', ifh)
    var time = 60

    const set = setInterval(function() {
      // 执行倒计时方法，一秒钟执行一次
      --time
      yamTex.value = `重新发送(${time}s)`
      yamDisabled.value = true
    }, 1000) // 此方法后面跟的是毫秒级单位

    // 60s之后执行的方法
    setTimeout(function() {
      yamTex.value = '获取验证码'
      yamDisabled.value = false
      clearInterval(set) // 同时停止上面重复方法的执行
    }, 60000)
  }
}
const codeBluerinput = vv => {
  if (vv.length === 4) {
    entDisabled.value = false
  } else {
    entDisabled.value = true
  }
  console.log(vv)
}

// 登录
const dlcs = ref(0)
const mobileFun = async () => {
  logform.value.sjs = Math.ceil(Math.random() * 10)
  if (dlcs.value > 0) {
    logform.value.tg = 'ok'
  }
  dlcs.value++
  phoneLogin(sign(logform.value)).then(res => {
    utoken.value = res.token
    setItem('token', res.token)
    res.userinfo.freeze = parseInt(res.userinfo.freeze)
    res.userinfo.integral = parseInt(res.userinfo.integral)
    setItem('userinfo', res.userinfo)
    utoken.value = res.token
    // userinfo.value = res.userinfo
    dialoginVisible.value = false
    location.reload()
    // window.location.href = '/'
  })
  // var fhz = await phoneLogin(sign(logform.value))
}
// 退出
const uslogoutFun = async () => {
  var fhz = await uslogout(sign({}))
  // var fhz = await uslogout({ tg: 'ok' })
  if (fhz === 'ok') {
    removeItem('token')
    removeItem('userinfo')
    utoken.value = ''
    // location.reload()
    window.location.href = '/'
  }
}
const router = useRouter()
const goPage = ss => {
  router.push(ss)
}
eventbus.on('loginWindow', ee => {
  if (ee === 'login') {
    dialoginVisible.value = true
  } else if (ee === 'loginqut') {
    uslogoutFun()
  }
  // console.log('打开登录窗口', ee)
})
</script>
<style scoped>
.logMx {
  margin: 1vw;
}
.inpNum {
  margin-bottom: 16px;
}
</style>
