import axios from 'axios'
import store from '@/store'
import { ElMessage } from 'element-plus'
import { removeItem } from '@/utils/storage'

// import { isCheckTimeout } from '@/utils/auth'
// import md5 from 'md5'
// console.log('widno-------', window.g)

const service = axios.create({
  baseURL: window.g.baseURL,
  timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 处理二进制流--下载
    // if (response.config.responseType) {
    //   return response.data
    // }
    // console.log(response.data)
    // debugger
    // 在这个位置统一注入token
    if (store.getters.token) {
      // 主动检查登录是否过期
      // if(isCheckTimeout()){
      //   // 登出操作
      //   store.dispatch('user/logout')
      //   return Promise.reject(new Error('token 失效'))
      // }
      // // 如果token存在，注入token
      // config.headers.Authorization = `Bearer ${store.getters.token}`
      config.headers.token = store.getters.token
    }
    // 有些数据是从接口获取的
    config.headers['Accept-Language'] = store.getters.language
    return config // 需返回配置
  },
  (error) => {
    // 处理token 超时
    if (error.response && error.response.data && error.response.data.code === 401) {
      // token 超时
      store.dispatch('user/logout')
    }
    ElMessage.error(error.message)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const { code, msg, data } = response.data
    // 要跟据success的成功与否决定下面的操作
    if (code === 200) {
      return data
    } else if (code === 500 && msg === '未登录') {
      store.dispatch('user/logout')
    } else if (code === 807) {
      console.log('用户token过期')
      ElMessage.error('用户token过期') // 显示错误
      removeItem('token')
      removeItem('userinfo')
      window.location.href = '/index'
      window.location.replace()
    } else if (code === 801) {
      // 接口加密验证不通过
      return Promise.reject(new Error(msg))
    } else {
      // 业务错误
      ElMessage.error(msg) // 显示错误
      return Promise.reject(new Error(msg))
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
