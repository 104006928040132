import request from '@/utils/request'

/**
 * 发送验证码
 */
export const syslogin = params => {
  return request({
    url: '/api/sendCode',
    method: 'get',
    params
  })
}

/**
 * 登录
 */
export const phoneLogin = params => {
  return request({
    url: '/api/phoneLogin',
    method: 'get',
    params
  })
}

/**
 * 退出
 */
export const uslogout = params => {
  return request({
    url: '/api/logout',
    method: 'get',
    params
  })
}

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return request({
    url: '/jyd/profile'
  })
}

/**
 * 字典表
 */
export const dictionary = params => {
  return request({
    url: '/api/dictionary',
    method: 'get',
    params
  })
}

/**
 * 字典表
 */
export const footerObj = params => {
  return request({
    url: '/api/footerContent',
    method: 'get',
    params
  })
}
