<template>
  <div class="footerDiv">
    <div class="footer nW">
      <div class="fotLikFx">
        <div class="link" v-html="footer.link"></div>
        <div class="serverTel" v-html="footer.serverTel"></div>
      </div>
      <div class="ctrl">
        <div class="copyright" v-html="footer.copyright1"></div>
        <div class="icp" v-html="footer.copyright2"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { sign } from '@/utils/storage'
import { footerObj } from '@/api/sys'
const footer = ref({})
const footerFun = async () => {
  footerObj(sign({ sjs: Math.ceil(Math.random() * 10) })).then(result => {
    console.log(result)
    footer.value = JSON.parse(result)
  })
}
footerFun()
</script>
